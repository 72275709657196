import React from 'react';
import {css} from '@emotion/react';
import {ClipLoader, RiseLoader, BarLoader, BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader, ClockLoader, DotLoader, FadeLoader, GridLoader, HashLoader, MoonLoader, PropagateLoader, PuffLoader, PulseLoader, ScaleLoader, SkewLoader, SquareLoader, SyncLoader} from 'react-spinners';

const LoadStatePage = ({show}) => {

	return (
		<div className={`${show ? 'modal-show flex justify-center' : 'modal'}`}>
            <div className="row w-100" id="page-chargement">
                <p className='text-bold text-white'>Traitement en cours ...</p>
            </div>
		</div>
	);
};

export default LoadStatePage;
