import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Link, Switch} from 'react-router-dom';
import PageChargement from './PageChargement';
import PageIndex from './PageIndex';
import CheminSecurise from './CheminSecurise';
import ConnectionSystem from './ConnectionSystem';
import ErreurPage from './ErreurPage';
import './App.css';
import './responsive.css';
function RootPage() {
  return (
  	<Router>
  		<ConnectionSystem>
  			<Routes>
          <Route path="/" element={<CheminSecurise component={PageIndex} />} />
          <Route path="*" element={<ErreurPage />} />
  			</Routes>
  		</ConnectionSystem>
  	</Router>
  );
}
function App() {
  const [chargement, setChargement] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setChargement(false);
    }, 2500)
    return () => clearTimeout(timer)
  }, []);
  return (
    <div>
      {chargement ? <PageChargement /> : <RootPage />}
    </div>
  );
}
export default App;
