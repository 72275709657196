import React, {useState, useRef, useContext, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {ConnectionContext} from './ConnectionSystem';
import removeItemIntoCart from './PageIndex';

function ModalEspaceAchat({show, closeModal, panierId, listeAchats, dataPanier, clientId}) {
	const {hermannlepatrioteClientlogin, hermannlepatrioteClientlogout, hermannlepatrioteClientconnecte, formatDateFr} = useContext(ConnectionContext);
	const [hlpId, setHlpid] = useState(localStorage.getItem('hermannlepatrioteClientid'));
	const [hlpEmail, setHlpemail] = useState(localStorage.getItem('hermannlepatrioteClientemail'));
	const [hlpLivraisonTemp, setHlplivraisontemp] = useState(localStorage.getItem('hlpLivraisonTemp'));
	const [espaceAchat, setEspaceachat] = useState(false);
	const [hermannlepatrioteClientEtat, setHermannlepatrioteclientetat] = useState('');
	const [dataListeAchats, setlisteAchatsData] = useState(listeAchats);
	const [dataListePanier, setlistePanierData] = useState(dataPanier);
	//
	const [commandePays, setCommandepays] = useState('');
	const commandePaysRef = useRef(null);
	const [commandeTel, setCommandetel] = useState('');
	const commandeTelRef = useRef(null);
	const [commandeVille, setCommandeville] = useState('');
	const commandeVilleRef = useRef(null);
	const [commandeEtat, setCommandeetat] = useState('');
	const commandeEtatRef = useRef(null);
	const [commandeCodepostal, setCommandecodepostal] = useState('');
	const commandeCodepostalRef = useRef(null);
	const [commandeAdresse, setCommandeadresse] = useState('');
	const commandeAdresseRef = useRef(null);
	const [commandeEmail, setCommandeemail] = useState('');
	const commandeEmailRef = useRef(null);
	const [commandeNom, setCommandenom] = useState('');
	const commandeNomRef = useRef(null);	
	const [commandeDate, setCommandedate] = useState('');
	const navigate = useNavigate();
	//
	useEffect(() => {
		if (show) {
			setlisteAchatsData(listeAchats);
			setlistePanierData(dataPanier);
		}
	}, [show]);
	function panierOptMinus(panier, achatid, achatcout, achatqte) {
		axios.post('https://api.hermannlepatriote.com/_010111SQL_/modificationachatsminus', {panier, achatid, achatcout, achatqte})
		.then(response => {
			switch(response.data.erreur) {
				case 'ErrorAchatDataMinus1':
					return alert(response.data.message);
				case 'ErrorUpdateAchatMinus1':
					return alert(response.data.message);
				case 'ErrorPanierDataMinus1':
					return alert(response.data.message);
				case 'ErrorUpdatePanierMinus1':
					return alert(response.data.message);
				case 'ErrorDataAchatsMinus1':
					return alert(response.data.message);
				case 'AchatMinusSuccess':				
					return refresh(response.data.dataachatsminus, response.data.datapanierminus);
				default:
					return null;
			}
		})
		.catch(error => {
			console.error('Erreur modification article moins 1 !', error);
		});
	}
	function panierOptPlus(panier, achatid, achatcout, achatqte) {
		axios.post('https://api.hermannlepatriote.com/_010111SQL_/modificationachatsplus', {panier, achatid, achatcout, achatqte})
		.then(response => {
			switch(response.data.erreur) {
				case 'ErrorAchatDataPlus1':
					return alert(response.data.message);
				case 'ErrorUpdateAchatPlus1':
					return alert(response.data.message);
				case 'ErrorPanierDataPlus1':
					return alert(response.data.message);
				case 'ErrorUpdatePanierPlus1':
					return alert(response.data.message);
				case 'ErrorDataAchatsPlus1':
					return alert(response.data.message);
				case 'AchatPlusSuccess':					
					return refresh(response.data.dataachatsplus, response.data.datapanierplus);
				default:
					return null;
			}
		})
		.catch(error => {
			console.error('Erreur modification article plus 1 !', error);
		});
	}
	function refresh(a,p) {
		setlisteAchatsData(a);
		setlistePanierData(p);
		if (p[0].panier_nombre == 0) {
			clearLivraisonDetail();
			localStorage.removeItem('hlpLivraisonTemp');
		}
	}
	function panierOptDelete(pId) {
		alert('effacer prod'+pId);
	}
	function saveLivraisonDetail() {		
		var tmplivraison = Date.now();
		setCommandedate(formatDateFr(new Date()));
		if (!commandePays) {
			alert('Entrez votre pays !');
			return commandePaysRef.current.focus();
		} else {
			if (!commandeVille) {
				alert('Entrez votre ville !');
				return commandeVilleRef.current.focus();
			} else {
				if (!commandeAdresse) {
					alert('Entrez votre adresse !');
					return commandeAdresseRef.current.focus();
				} else {
					if (!commandeEmail) {
						alert('Entre votre e-mail !');
						return commandeEmailRef.current.focus();
					} else {
						if (!commandeEtat) {
							if (!commandeCodepostal) {
								if (!commandeTel) {
									var dataLv = [tmplivraison, dataListePanier[0].panier_numero, commandePays, commandeVille, "Etat", "Code postal", commandeAdresse, commandeEmail, commandeNom, "Telphone", commandeDate];
									localStorage.removeItem('hlpLivraisonTemp');
									setHlplivraisontemp(dataLv);
									localStorage.setItem('hlpLivraisonTemp', dataLv);
								} else {
									var dataLv = [tmplivraison, dataListePanier[0].panier_numero, commandePays, commandeVille, "Etat", "Code postal", commandeAdresse, commandeEmail, commandeNom, commandeTel, commandeDate];
									localStorage.removeItem('hlpLivraisonTemp');
									setHlplivraisontemp(dataLv);
									localStorage.setItem('hlpLivraisonTemp', dataLv);
								}
							} else {
								if (!commandeTel) {
									var dataLv = [tmplivraison, dataListePanier[0].panier_numero, commandePays, commandeVille, "Etat", commandeCodepostal, commandeAdresse, commandeEmail, commandeNom, "Telphone", commandeDate];
									localStorage.removeItem('hlpLivraisonTemp');
									setHlplivraisontemp(dataLv);
									localStorage.setItem('hlpLivraisonTemp', dataLv);
								} else {
									var dataLv = [tmplivraison, dataListePanier[0].panier_numero, commandePays, commandeVille, "Etat", commandeCodepostal, commandeAdresse, commandeEmail, commandeNom, commandeTel, commandeDate];
									localStorage.removeItem('hlpLivraisonTemp');
									setHlplivraisontemp(dataLv);
									localStorage.setItem('hlpLivraisonTemp', dataLv);
								}
							}
						} else {
							if (!commandeCodepostal) {
								if (!commandeTel) {
									var dataLv = [tmplivraison, dataListePanier[0].panier_numero, commandePays, commandeVille, commandeEtat, "Code postal", commandeAdresse, commandeEmail, commandeNom, "Telphone", commandeDate];
									localStorage.removeItem('hlpLivraisonTemp');
									setHlplivraisontemp(dataLv);
									localStorage.setItem('hlpLivraisonTemp', dataLv);
								} else {
									var dataLv = [tmplivraison, dataListePanier[0].panier_numero, commandePays, commandeVille, commandeEtat, "Code postal", commandeAdresse, commandeEmail, commandeNom, commandeTel, commandeDate];
									localStorage.removeItem('hlpLivraisonTemp');
									setHlplivraisontemp(dataLv);
									localStorage.setItem('hlpLivraisonTemp', dataLv);
								}
							} else {
								if (!commandeTel) {
									var dataLv = [tmplivraison, dataListePanier[0].panier_numero, commandePays, commandeVille, commandeEtat, commandeCodepostal, commandeAdresse, commandeEmail, commandeNom, "Telphone", commandeDate];
									localStorage.removeItem('hlpLivraisonTemp');
									setHlplivraisontemp(dataLv);
									localStorage.setItem('hlpLivraisonTemp', dataLv);
								} else {
									var dataLv = [tmplivraison, dataListePanier[0].panier_numero, commandePays, commandeVille, commandeEtat, commandeCodepostal, commandeAdresse, commandeEmail, commandeNom, commandeTel, commandeDate];
									localStorage.removeItem('hlpLivraisonTemp');
									setHlplivraisontemp(dataLv);
									localStorage.setItem('hlpLivraisonTemp', dataLv);
								}
							}
						}
					}
				}
			}
		}
	}
	function clearLivraisonDetail() {
		setCommandepays('');
		setCommandeville('');
		setCommandeetat('');
		setCommandecodepostal('');
		setCommandeadresse('');
		setCommandeemail('');
		setCommandenom('');
		setCommandetel('');
		setCommandedate('');
	}
	function applyCouponCode() {
		alert('valider coupon !!');
	}
	const validerCommande = async() => {
		if (dataPanier[0].panier_nombre)  {
			saveLivraisonDetail();
			if (localStorage.getItem('hlpLivraisonTemp')) {
				axios.post('https://api.hermannlepatriote.com/_010111SQL_/enregistrerdetailslivraison', {hlpLivraisonTemp})
				.then(response => {
					switch(response.data.erreur) {
						case 'ErrorPanierLivraison':
							alert(response.data.message);
							return false;
						case 'Erro rLivraisonSave':
							alert(response.data.message);
							return false;
						case 'ErrorUpdatePanierCommande':
							alert(response.data.message);
							return false;
						case 'LivraisonSuccess':
							return clearDataAfterCommande();
					}
				})
				.catch(error => {
					console.error('Erreur enregistrement livraison !', error);
					return false;
				});
			} else {
				//saveLivraisonDetail()
				return false;
			}
		} else {
			alert('Votre panier est vide !');
			return false;
		}
	};
	function clearDataAfterCommande() {
		var tmpp1 = Date.now();
		var tmpl1 = Date.now()+1;
		localStorage.setItem('hlpPanier', tmpp1);
		localStorage.setItem('hlpLivraisonTemp', tmpl1);
		localStorage.setItem('hlpPaiementId', dataListePanier[0].panier_numero);
		localStorage.setItem('hlpPaiement', dataListePanier[0].panier_cout);
		localStorage.setItem('hlpPaiementCountry', commandePays);
		localStorage.setItem('hlpPaiementReady', true);
		alert('Votre commande est enregistrée !\r\nMerci de procéder au paiement.');
		window.location.replace('/');
	}
	function faireDesAchats() {
		window.open('/');
	}

	return (
		<div className={`${show ? 'modal-show' : 'modal'}`}>
			<div className="modal-achats-content shadow-xl">
				<p className="text-2xl border-b border-gray-200 py-2 px-3">
					Passez une commande
					<span className="close-button bg-gray-200 px-3 border-gray-300 border" onClick={closeModal}>&times;</span>
				</p>
				<div className="w-full flex flex-wrap bg-gray-50 p-4">
					<div className="w-panier-achats bg-green-100 mx-auto p-3" id="liste-produits-panier">
						{
							dataListeAchats && dataListeAchats.length > 0 ? (
								<div className="w-full flex flex-col">
									{
										dataListeAchats.map((prod) => (
											<div className="w-full flex w-panieritem mb-3 border-gray-400 border-r rounded-br-3xl border-b pb-2">
												<div className="flex items-center justify-center flex-wrap w-panieritem-titre">
													<div className="w-panieritem-image">
														<img className="rounded-md shadow-md" src={prod.achat_image} alt={prod.achat_nom} />
													</div>
													<div className="w-panieritem-nom px-1 flex flex-col justify-center items-cen">
														<p className="text-xl font-bold text-black mb-2">
															{prod.achat_nom}
														</p>
														<span className="mx-2 font-bold text-indigo-800">
															$ {prod.achat_prix / prod.achat_qte}
														</span>
													</div>
												</div>
												<div className="flex items-center justify-center flex-wrap w-panieritem-infos">
													<div className="flex items-center w-1/2 pl-1 justify-center">
														<button onClick={() => panierOptMinus(`${prod.achat_panier}`, `${prod.achat_numserie}`, `${prod.achat_prix}`, `${prod.achat_qte}`)}>
															<svg className="w-7 h-7 hover:text-gray-600 transition-all duration-500 text-orange-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
																<path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z" clip-rule="evenodd"/>
															</svg>
														</button>
														<span className="w-full p-3 mx-1 rounded-3xl outline-none font-bold text-xs text-center bg-gray-300">{prod.achat_qte}</span>
														<button onClick={() => panierOptPlus(`${prod.achat_panier}`, `${prod.achat_numserie}`, `${prod.achat_prix}`, `${prod.achat_qte}`)}>
															<svg className="w-7 h-7 hover:text-gray-600 text-green-600 transition-all duration-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
																<path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clip-rule="evenodd"/>
															</svg>
														</button>
													</div>
													<h6 className="text-red-500 text-center w-1/2 font-bold text-xl">
														$ {prod.achat_prix}
													</h6>
												</div>
											</div>
										))
									}
								</div>
							) : (
								<Link onClick={() => faireDesAchats()} className="text-base underline text-gray-800 text-center flex flex-wrap mt-10 ml-20">
									Panier vide, faites vos achats 
									<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
										<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7h-1M8 7h-.688M13 5v4m-2-2h4"/>
									</svg>
								</Link>
							)
						}
					</div>
					<div className="w-panier-informations mx-auto">
						<div className="w-full bg-gray-100 p-3" id="formulaire-commande">
							{
								dataListePanier && dataListePanier.length > 0 ? (
									<div className="bg-white p-5 rounded-xl mb-6">
										{
											dataListePanier.map((pan) => (
												<div className="w-full mb-8 max-lg:max-w-xl max-lg:mx-auto">
													<div className="flex items-center justify-between w-full mb-1 border-b border-gray-200">
														<p className="font-normal text-xl text-gray-400">
															Quantité
														</p>
														<h6 className="font-semibold text-xl leading-8 text-gray-700">
															{pan.panier_nombre}
														</h6>
													</div>
													<div className="flex items-center justify-between w-full mb-6 border-b border-gray-200">
														<p className="font-normal text-xl text-gray-400">
															Remise
														</p>
														<h6 className="font-semibold text-xl leading-8 text-gray-700">
															$ {pan.panier_client}
														</h6>
													</div>
													<div className="flex items-center justify-between w-full">
														<p className="font-normal text-xl leading-8 text-gray-400">
															Total
														</p>
														<h6 className="font-bold text-xl text-indigo-700">
															$ {pan.panier_cout}
														</h6>
													</div>
												</div>
											))
										}	
									</div>
								) : (
									<div className="bg-gray-50 rounded-xl p-6 w-full mb-8 max-lg:max-w-xl max-lg:mx-auto">
									</div>
								)
							}
							<div className="bg-white rounded-xl p-2 w-full">
								<span className="text-sm text-gray-400 mb-4">Information de livraison:</span>
								<div className="flex flex-col items-center justify-between w-full mb-6">
									<select className="mb-2 w-full rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" ref={commandePaysRef} value={commandePays} onChange={(e) => setCommandepays(e.target.value)}>
										<option value="----">Selectionnez votre pays</option>
										<option value="eur">Irlande</option>
										<option value="nzd">Nouvelle Zélande</option>
										<option value="krw">Corée du Sud</option>
										<option value="ncy">Chine</option>
										<option value="usd">USA</option>
										<option value="jpy">Japon</option>
										<option value="eur">France</option>
										<option value="rub">Russie</option>
										<option value="ngn">Nigeria</option>
										<option value="ghs">Ghana</option>
										<option value="xof">Côte d'Ivoire</option>
										<option value="xof">Mali</option>
										<option value="xof">Burkina Faso</option>
										<option value="mad">Maroc</option>
										<option value="tnd">Tunisie</option>
										<option value="zar">Afrique du Sud</option>
										<option value="cad">Canada</option>
										<option value="kes">Kenya</option>
										<option value="ugx">Ouganda</option>
										<option value="bdt">Bangladesh</option>
										<option value="idr">Indonesie</option>
										<option value="gbp">Royaume Uni</option>
										<option value="dkk">Danemark</option>
									</select>
									<input ref={commandeVilleRef} type="text" value={commandeVille} className="w-full mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" onChange={(e) => setCommandeville(e.target.value)} placeholder="Ville" />
									<div className="w-full justify-between flex flex-wrap items-center pl-2">
										<div className="w-1/12 flex flex-wrap justify-center items-center">
											<svg className="w-6 h-6 mb-3 text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" viewBox="0 0 24 24">
												<path fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z" clip-rule="evenodd"/>
											</svg>
										</div>
										<input ref={commandeEtatRef} type="text" value={commandeEtat} className="w-11/12 mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" onChange={(e) => setCommandeetat(e.target.value)} Placeholder="Etat / Province" />
									</div>
									<div className="w-full justify-between flex flex-wrap items-center pl-2">
										<div className="w-1/12 flex flex-wrap justify-center items-center"> 
											<svg className="w-6 h-6 mb-3 text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
												<path fill-rule="evenodd" d="M5.024 3.783A1 1 0 0 1 6 3h12a1 1 0 0 1 .976.783L20.802 12h-4.244a1.99 1.99 0 0 0-1.824 1.205 2.978 2.978 0 0 1-5.468 0A1.991 1.991 0 0 0 7.442 12H3.198l1.826-8.217ZM3 14v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-4.43a4.978 4.978 0 0 1-9.14 0H3Zm5-7a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm0 2a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2H8Z" clip-rule="evenodd"/>
											</svg>
										</div>										
										<input ref={commandeCodepostalRef} type="text" value={commandeCodepostal} className="w-11/12 mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" onChange={(e) => setCommandecodepostal(e.target.value)} Placeholder="Boîte postal" />
									</div>
									<input ref={commandeAdresseRef} type="text" value={commandeAdresse} className="w-full mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" onChange={(e) => setCommandeadresse(e.target.value)} Placeholder="Adresse" />
									<input ref={commandeEmailRef} type="text" value={commandeEmail} className="w-full mb-2 rounded-md outline-none border-blue-500 shadow px-2 py-1 text-gray-800" onChange={(e) => setCommandeemail(e.target.value)} Placeholder="@" />
									<div className="w-full justify-between flex flex-wrap items-center pl-2">
										<div className="w-1/12 flex flex-wrap justify-center items-center"> 
											<svg class="w-6 h-6 mb-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
												<path fill-rule="evenodd" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z" clip-rule="evenodd"/>
											</svg>
										</div>
										<input ref={commandeNomRef} type="text" value={commandeNom} className="w-11/12 mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" onChange={(e) => setCommandenom(e.target.value)} Placeholder="Nom & prénoms" />
									</div>
									<div className="w-full justify-between flex flex-wrap items-center pl-2">
										<div className="w-1/12 flex flex-wrap justify-center items-center"> 
											<svg class="w-6 h-6 mb-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
												<path fill-rule="evenodd" d="M12 2a7 7 0 0 0-7 7 3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h1a1 1 0 0 0 1-1V9a5 5 0 1 1 10 0v7.083A2.919 2.919 0 0 1 14.083 19H14a2 2 0 0 0-2-2h-1a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h1a2 2 0 0 0 1.732-1h.351a4.917 4.917 0 0 0 4.83-4H19a3 3 0 0 0 3-3v-2a3 3 0 0 0-3-3 7 7 0 0 0-7-7Zm1.45 3.275a4 4 0 0 0-4.352.976 1 1 0 0 0 1.452 1.376 2.001 2.001 0 0 1 2.836-.067 1 1 0 1 0 1.386-1.442 4 4 0 0 0-1.321-.843Z" clip-rule="evenodd"/>
											</svg>
										</div>
										<input ref={commandeTelRef} type="text" value={commandeTel} className="w-11/12 mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" onChange={(e) => setCommandetel(e.target.value)} Placeholder="Contact" />	
									</div>
								</div>
							</div>
							<div className="flex items-center flex-col max-w-[1920px]: justify-center mt-8">
								<button onClick={validerCommande} className="mt-4 transition-all duration-200 rounded-full py-4 w-full flex items-center bg-green-200 justify-center hover:bg-green-100 cursor-pointer font-semibold text-lg text-green-600 shadow-sm">
									Commandez
									<svg className="w-[32px] h-[32px] text-green-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
										<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 12H5m14 0-4 4m4-4-4-4"/>
									</svg>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ModalEspaceAchat;
