import React, {createContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';

export const ConnectionContext = createContext();

const ConnectionSystem = ({children}) => {
	const [connectToken, setConnecttoken] = useState(null);
	const [hermannlepatrioteClientemail, sethermannlepatrioteClientemail] = useState(null);
	const [hermannlepatrioteClientid, setHermannlepatrioteclientid] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const cT = localStorage.getItem('connectToken');
		const cId = localStorage.getItem('hermannlepatrioteClientid');
		const cPs = localStorage.getItem('hermannlepatrioteClientemail');
		if (cT) {
			setConnecttoken(cT);
			setHermannlepatrioteclientid(cId);
			sethermannlepatrioteClientemail(cPs);
		}
	}, []);

	const hermannlepatrioteClientlogin = (jeton) => {
		localStorage.setItem('connectToken', jeton);
		setConnecttoken(jeton);
		const myJeton = jwtDecode(jeton);
		localStorage.setItem('hermannlepatrioteClientid', myJeton.hlpclientid);
		localStorage.setItem('hermannlepatrioteClientemail', myJeton.hlpclientemail);
		navigate('/');
	};
	const hermannlepatrioteClientlogout = () => {
		localStorage.removeItem('connectToken');
		localStorage.removeItem('hermannlepatrioteClientid');
		localStorage.removeItem('hermannlepatrioteClientemail');
		setConnecttoken(null);
		navigate('/');
	};
	const hermannlepatrioteClientconnecte = () => {
		/*if (!connectToken) return false;
		const decodedJeton = jwtDecode(connectToken);
		return decodedJeton.exp * 1000 > Date.now();*/
	};
	const formatDateFr = (datetm) => {
		const jour = new Intl.DateTimeFormat('fr-FR', {weekday: 'short'}).format(datetm);
		const dJour = String(datetm.getDate()).padStart(2, '0');
		const mois = new Intl.DateTimeFormat('fr-FR', {month: 'long'}).format(datetm);
		const annee = datetm.getFullYear();
		const dHeure = String(datetm.getHours()).padStart(2, '0');
		const dMin = String(datetm.getMinutes()).padStart(2, '0');
		const dSec = String(datetm.getSeconds()).padStart(2, '0');

		return `${jour.charAt(0).toUpperCase()+jour.slice(1)} ${dJour} ${mois} ${annee} - ${dHeure}:${dMin}:${dSec}`;
	};
	const formatDateDuree = (tmp) => {
		let ecart = Math.abs(Date.now() - tmp);
		const tpSec = 1000;
		const tpMin = tpSec * 60;
		const tpHour = tpMin * 60;
		const tpDay = tpHour * 24;
		const tpSem = tpDay * 7;
		const tpMois = tpDay * 30.44;
		const tpYear = tpDay * 365.25;

		const ann = Math.floor(ecart / tpYear);
		ecart -= ann*tpYear;
		const mois = Math.floor(ecart / tpMois);
		ecart -= mois*tpMois;
		const sem = Math.floor(ecart / tpSem);
		ecart -= sem*tpSem;
		const jours = Math.floor(ecart / tpDay);
		ecart -= jours*tpDay;
		const heur = Math.floor(ecart / tpHour);
		ecart -= heur*tpHour;
		const mins = Math.floor(ecart / tpMin);
		ecart -= mins*tpMin;
		const secs = Math.floor(ecart / tpSec);
		if (ann > 0) {
			return `${ann} an(s)`;
		} else {
			if (mois > 0) {
				return `${mois} mois`;
			} else {
				if (sem > 0) {
					return `${sem} semaine(s)`;
				} else {
					if (jours > 0) {
						return `${jours} jour(s)`;
					} else {
						if (heur > 0) {
							return `${heur} heure(s)`;
						} else {
							if (mins > 0) {
								return `${mins} minute(s)`;
							} else {
								if (secs > 0) {
									return `${secs} seconde(s)`;
								}
							}
						}
					}
				}
			}
		}
	};

	return (
		<ConnectionContext.Provider value={{connectToken, hermannlepatrioteClientlogin, hermannlepatrioteClientlogout, hermannlepatrioteClientemail, hermannlepatrioteClientid, hermannlepatrioteClientconnecte, formatDateDuree, formatDateFr}}>
			{children}
		</ConnectionContext.Provider>
	);
};

export default ConnectionSystem;
