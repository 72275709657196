import React, {useState, useEffect, useContext, useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import PageFooter from './PageFooter';
import './App.css';
import CheminSecurise from './CheminSecurise';
import {ConnectionContext} from './ConnectionSystem';
import ModalVoirBoutique from './ModalVoirBoutique';
import ModalEspaceAchat from './ModalEspaceAchat';
import LoadStatePage from './LoadStatePage';
import FlashInfos from './FlashInfos';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';

//const stripePromise = loadStripe('pk_test_51PwusTKNPW6PkIDiz6kJzfjCfr2kiZirEUR8hISq7Q0QvnkwQD4ltOUinTIjp1RGMtwnOhP2hIYLdrRQHHbPA6wE00h56VwaYq');
const stripePromise = loadStripe('pk_live_51PwusTKNPW6PkIDiA7M1jeVZzZW1j7mGZzVIe8DEGAiR4Cexi14dBnRhTBZHZPetSpA4MvpRIaT3J6o22ete0cxz00qWDS3TkH');
const CheckoutForm = ({setPayment}) => {

  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
	if (!stripe || !elements) {
		// Stripe.js has not yet loaded
		return;
	}
	const { error } = await stripe.confirmPayment({
		elements,
		confirmParams: {},
		redirect: 'if_required'
	});
	if (error) {
		setErrorMessage(error.message);
	} else {
		// Handle successful payment
		alert('payement effectué !');
		localStorage.removeItem('hlpPaiement');
		localStorage.removeItem('hlpPaiementReady');
		window.location.replace('/');
	}
	setIsProcessing(false);    
  };

  return (
	<div className={`${setPayment ? 'modal-show flex justify-center items-center' : 'modal'}`}>
		<form className="bg-white p-4 rounded-sm shadow-md" onSubmit={handleSubmit}>
			<PaymentElement />
			<button type="submit" disabled={!stripe || isProcessing} className="mt-4 transition-all duration-200 rounded-full py-4 w-full flex items-center bg-blue-200 justify-center hover:bg-green-100 cursor-pointer">
				<span className="font-semibold text-lg text-blue-600">
					{isProcessing ? 'Patientez...' : 'Payez maintenant !'}
				</span>
			</button>
			{errorMessage && <div className="text-red-400 font-bold">{errorMessage}</div>}
		</form>
	</div>
  );
};
const PaymentForm = ({ clientSecret, setPay }) => (
	<Elements stripe={stripePromise} options={{ clientSecret }}>
		<CheckoutForm setPayment={setPay} />
	</Elements>
);

function PageIndex() {
	const {hermannlepatrioteClientlogin, hermannlepatrioteClientlogout, hermannlepatrioteClientconnecte, formatDateFr} = useContext(ConnectionContext);
	const [dateEncours, setDateencours] = useState('');
	//
	const [hlpId, setHlpid] = useState(localStorage.getItem('hermannlepatrioteClientid'));
	const [hlpEmail, setHlpemail] = useState(localStorage.getItem('hermannlepatrioteClientemail'));
	const [hlpPanierTemp, setHlppaniertemp] = useState(localStorage.getItem('hlpPanier'));
	const [hlpPaiementId, setHlppaiementid] = useState(localStorage.getItem('hlpPaiementId'));
	const [hlpPaiement, setHlppaiement] = useState(localStorage.getItem('hlpPaiement'));
	const [hlpPaiementReadyId, setHlppaiementReady] = useState(localStorage.getItem('hlpPaiementReady'));
	const [hlpPaiementCountry, setHlppaiementcountry] = useState(localStorage.getItem('hlpPaiementCountry'));
	//
	const [dataBoutik, setDataboutik] = useState([]);
	const [activeTab, setActivetab] = useState('actu-1');
	const [voirBoutik, setVoirboutik] = useState(false);
	const [voirBoutikNom, setVoirboutiknom] = useState('');
	const [voirBoutikimageprincipale, setVoirboutikimageprincipale] = useState('');
	const [voirBoutikimage1, setVoirboutikimage1] = useState('');
	const [voirBoutikimage2, setVoirboutikimage2] = useState('');
	const [voirBoutikimage3, setVoirboutikimage3] = useState('');
	const [voirBoutikimage4, setVoirboutikimage4] = useState('');
	const [voirBoutikcout, setVoirboutikcout] = useState('');
	const [voirBoutikinfos, setVoirboutikinfos] = useState('');
	const [voirBoutikid, setVoirboutikid] = useState('');
	const [voirBoutiktype, setVoirboutiktype] = useState(0);
	//
	const [espaceAchat, setEspaceachat] = useState(false);
	const [espaceAchatpanierid, setEspaceachatpanierid] = useState('');
	const [espaceAchatprodnom, setEspaceachatprodnom] = useState('');
	const [espaceAchatprodimage, setEspaceachatprodimage] = useState('');
	const [espaceAchatprodcout, setEspaceachatprodcout] = useState('');
	const [panierListe, setPanierliste] = useState([]);
	const [panierData, setPanierdata] = useState([]);
	const [qtePanier, setQtepanier] = useState(0);
	const clientemailNewsletterRef = useRef(null);
	const [clientemailNewsletter, setclientemailNewsletter] = useState('');
	const [clientTotalvisites, setClienttotalvisites] = useState(0);
	const [pageRecherche, setPagerecherche] = useState('');
	const [options, setOptions] = useState('');
	const [loadState, setLoadstate] = useState(false);
	const [clientSecret, setClientSecret] = useState('');
	const [paymentReady, setPaymentReady] = useState(false);
	//
	const navg = useNavigate();
	//
	useEffect(() => {
		enregistrerVisite();
		fetchDataboutik();
		fecthDataPaiement();
		const intr = setInterval(() => {
			setDateencours(formatDateFr(new Date()));
		}, 1000);		
	}, []);

	const fetchDataboutik = async() => {
		if (hlpPanierTemp) {
			var p = hlpPanierTemp;
			var datePanier = formatDateFr(new Date());
			setLoadstate(true);
			try {
				const resp = await axios.post('https://api.hermannlepatriote.com/_010111SQL_/lesproduitsdelaboutique', {p, datePanier});
				switch (resp.data.erreur) {
					case 'ErreurPanier':
						return alert('Erreur panier: '+resp.data.message);
					case 'ErreurAchat':
						return alert('Erreur achat: '+resp.data.message);
					case 'CreationNouveauPanier':
						return fillData(resp.data.dataBoutique, resp.data.dataAchatLoad, resp.data.dataPanierLoad, resp.data.qtePanierLoad);
					case 'ErreurCreationPanier':
						return alert('Erreur creation: '+resp.data.message);
					case 'ErreurProduits':
						return alert('Erreur sur la boutique '+resp.data.message);
					default:
						return null;
				}
			} catch (error) {
				console.error('Erreur liste des produits !', error);
			} finally {
				setLoadstate(false);
			}
		} else {
			var tmpanier = Date.now();
			localStorage.setItem('hlpPanier', tmpanier);
			setHlppaniertemp(tmpanier);
			var p = hlpPanierTemp;
			var datePanier = formatDateFr(new Date());
			setLoadstate(true);
			try {
				const resp = await axios.post('https://api.hermannlepatriote.com/_010111SQL_/lesproduitsdelaboutique', {p, datePanier});
				switch (resp.data.erreur) {
					case 'ErreurPanier':
						return alert('Erreur panier: '+resp.data.message);
					case 'ErreurAchat':
						return alert('Erreur achat: '+resp.data.message);
					case 'CreationNouveauPanier':
						return fillData(resp.data.dataBoutique, resp.data.dataAchatLoad, resp.data.dataPanierLoad, resp.data.qtePanierLoad);
					case 'ErreurCreationPanier':
						return alert('Erreur creation: '+resp.data.message);
					case 'ErreurProduits':
						return alert('Erreur sur la boutique '+resp.data.message);
					default:
						return null;
				}
			} catch (error) {
				console.error('Erreur liste des produits !', error);
			} finally {
				setLoadstate(false);
			}
		}
	};
	const fecthDataPaiement = async() => {
		if (hlpPaiement) {
			var somm = hlpPaiement*100;
			/*const somm = new Intl.NumberFormat('en-US', {
				style: 'decimal',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			}).format(hlpPaiement);*/
			if (hlpPaiementReadyId) {
				setPaymentReady(true);
			}
			fetch('https://api.hermannlepatriote.com/_001101BOX_/clienttoken', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ amount: somm, currency: hlpPaiementCountry }),
			})
			.then((res) => res.json())
			.then((data) => setClientSecret(data.clientSecret));
		}
	};
	const fermerModalBoutik = () => {
		setVoirboutik(false);
		fetchDataboutik();
	};
	const insertPrduitIntoCart = async(i, n, im, c) => {
		var achatDate = formatDateFr(new Date());
		var p = hlpPanierTemp;
		try {
			const response = await axios.post('https://api.hermannlepatriote.com/_010111SQL_/insertionachathorsligne', {p, i, n, im, c, achatDate})
			switch(response.data.erreur) {
				case 'ErreurAchatCheck1':
					return alert(response.data.message);
				case 'AchatError':
					return alert(response.data.message);
				case 'PanierError':
					return alert(response.data.message);
				case 'PanierAchatSuccess1':
					setPanierliste(response.data.listeachats);
					setPanierdata(response.data.listepanier);
					setEspaceachat(true);
				case 'PanierAchatSuccess2':
					setPanierliste(response.data.listeachats);
					setPanierdata(response.data.listepanier);
					setEspaceachat(true);
				default:
					return null;
			}			
		} catch (error) {
			console.error('Erreur liste des produits !', error);
		} finally {
			setLoadstate(false);
		}
	};
	function newsletterSuccessMessage() {
		setclientemailNewsletter('');
		navg('/');
	}
	function newsletterExistMessage() {
		setclientemailNewsletter('');
		clientemailNewsletterRef.current.focus();
		alert('Cette adresse est deja enregistré !\r\nMerci');
	}
	const _sendNewsletter_ = async() => {
		var emDate = formatDateFr(new Date());
		var email = clientemailNewsletter;
		axios.post('https://api.hermannlepatriote.com/_010111SQL_/envoinewsletter', {email, emDate})
		.then(response => {
			switch (response.data.erreur) {
				case 'ErreurNewsletter':
					return alert('Erreur sur la newsletter !\r\nVeuillez réessayer plus tard.'); 
				case 'SuccessNewsletter':
					return newsletterSuccessMessage();
				case 'ExistNewsletter':
					return newsletterExistMessage();
				default:
					return null;
			}
		})
		.catch(error => {
			console.log('Erreur de ajout newsletter!',error);
		});
	};
	function ouvrirModalAchat(p, n, i, c) {
		insertPrduitIntoCart(p, n, i, c);
		setEspaceachatpanierid(hlpPanierTemp);
	};
	function ouvrirModalBoutik(n, ip, i1, i2, i3, i4, p, i, s, t) {
		setVoirboutiknom(n);
		setVoirboutikimageprincipale(ip);
		setVoirboutikimage1(i1);
		setVoirboutikimage2(i2);
		setVoirboutikimage3(i3);
		setVoirboutikimage4(i4);
		setVoirboutikcout(p);
		setVoirboutikinfos(i);
		setVoirboutikid(s);
		setVoirboutiktype(t);
		setVoirboutik(true);
	};
	function envoyerMailnewsletter() {
		if (!clientemailNewsletter) {
			clientemailNewsletterRef.current.focus()
			alert('Adresse e-mail incorrecte !');
		} else {
			_sendNewsletter_();
		}
	}
	const fermerEspaceAchat = () => {
		setEspaceachat(false);
		fetchDataboutik();
	};
	const [espaceClient, setEspaceclient] = useState(false);
	const ouvrirEspaceclient = () => {
		setEspaceclient(true);
	};
	const ouvrirEspaceachat = () => {
		setEspaceachat(true);
	};
	const fermerEspaceclient = () => {
		setEspaceclient(false);
	};
	const ouvrirPanier = () => {
	};
	const fermerProfile = () => {
		hermannlepatrioteClientlogout();
	};
	function fillData(b, a, p, q) {
		setDataboutik(b);
		setPanierliste(a);
		setPanierdata(p);
		setQtepanier(q);
	}
	async function enregistrerVisite() {
		var vsDate = formatDateFr(new Date());
		console.log('Page web correcte !');
		/*axios.post('https://api.hermannlepatriote.com/_010111SQL_/definirpagevisiteur', {vsDate})
		.then(response => {
			switch (response.data.erreur) {
				case 'ErreurVisites':
					return alert('Erreur de page web !');
				case 'SuccessVisites':
					console.log('Page web correcte !');
				default:
					return null;
			}
		})
		.catch(error => {
			console.error('Erreur sur la visite !',error);
		});*/
	}

	return (
		<div className="flex flex-col" id="page-loading-state">
			{
				clientSecret ? <PaymentForm clientSecret={clientSecret} setPay={paymentReady}/> : <div></div>
			}
			<LoadStatePage show={loadState} />
			<ModalVoirBoutique panierId={hlpPanierTemp} boutik={voirBoutik} nomProd={voirBoutikNom} imagePrincipale={voirBoutikimageprincipale} image1={voirBoutikimage1} image2={voirBoutikimage2} image3={voirBoutikimage3} image4={voirBoutikimage4} coutProd={voirBoutikcout} infoProd={voirBoutikinfos} serieProd={voirBoutikid} boutikType={voirBoutiktype} closeBoutik={fermerModalBoutik} />
			<ModalEspaceAchat show={espaceAchat} closeModal={fermerEspaceAchat} panierId={espaceAchatpanierid} listeAchats={panierListe} dataPanier={panierData} clientId={hlpId+hlpEmail} />
			<div className="w-full flex flex-col">
				<div className="w-screen justify-center bg-blue-800 text-white flex flex-wrap py-1">
					<div className="w-2/12" id="page-top-left">&nbsp;</div>
					<div className="w-8/12 px-3 flex justify-between items-center" id="page-top-center">
						<div className="w-auto flex flex-wrap md:text-xs sm:text-xs">
							<span className="mr-6 hidden">{clientTotalvisites} visite(s)</span>
							<span>{dateEncours}</span>
						</div>
						<div className="w-auto flex flex-wrap">
							<Link to="https://www.instagram.com/hermann_le_patriote" target="_blank">
								<svg className="w-6 h-6 mx-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
									<path fill="currentColor" fill-rule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clip-rule="evenodd"/>
								</svg>
							</Link>
							<Link to="https://www.facebook.com/lePatrioteHermann?mibextid=LQQJ4d" target="_blank">
								<svg className="w-6 h-6  mx-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
									<path fill-rule="evenodd" d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z" clip-rule="evenodd"/>
								</svg>
							</Link>
							<Link to="https://m.youtube.com/c/HermannIvoirienUsa" target="_blank">
								<svg className="w-6 h-6 mx-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
									<path fill-rule="evenodd" d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z" clip-rule="evenodd"/>
								</svg>								
							</Link>
						</div>
					</div>
					<div className="w-2/12" id="page-top-right">&nbsp;</div>
				</div>
				<div className="w-screen justify-center flex flex-wrap py-6 border-gray-100 border-b">
					<div className="w-2/12" id="page-top-left">&nbsp;</div>
					<div className="w-8/12 px-3 flex justify-between items-center" id="page-top-center">
						<div className="w-4/5 bg-blue-100 flex flex-wrap drop-shadow">
							<img src="images/banner.jpg" alt="banner" />
						</div>
						<div className="w-1/5 justify-center px-4 md:px-2 flex flex-wrap">
							<button id="transition-effect" className="border shadow-md border-gray-300 hover:bg-blue-300  rounded-md pr-4 pl-3 py-2 flex flex-wrap items-center" onClick={ouvrirEspaceachat}>
								<svg className="text-gray-800 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 24 24">
									<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm8 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.5-3h9.25L19 7H7.312"/>
								</svg>
								<span className="text-xl">
									${qtePanier}
								</span>
							</button>
						</div>
					</div>
					<div className="w-2/12" id="page-top-right">&nbsp;</div>
				</div>
				<div className="w-screen justify-center flex flex-wrap py-8 border-gray-50 border-b shadow-lg">
					<div className="w-2/12" id="page-top-left">&nbsp;</div>
					<div className="w-8/12 px-3 flex items-center" id="page-top-center">
						<div className="w-1/6 bg-red-400 py-2 rounded text-white font-semibold drop-shadow-md">
							<p className="text-center md:text-sm sm:text-xs xs:text-xs">FLASH INFOS :</p>
						</div>
						<FlashInfos />
					</div>
					<div className="w-2/12" id="page-top-right">&nbsp;</div>
				</div>
			</div>
			<div className="flex flex-col bg-gray-50 pt-10 mt-4">
				<div className="w-full flex justify-center flex-col">
					<div className="w-full flex justify-center">
						<div className="w-6/12 bg-white rounded-2xl flex flex-wrap">
							<input value={pageRecherche} onChange={(e) => setPagerecherche(e.target.value)} type="text" className="w-5/6 py-3 rounded-l-2xl outline-none border-none" placeholder="Recherche ..." />
							<span onClick={() => setPagerecherche('')} className="bg-blue-200 hover:bg-blue-300 duration-400 transition-all cursor-pointer w-1/6 flex py-3 items-center justify-center rounded-r-2xl">
								<strong>
									<svg className="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
										<path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
									</svg>
								</strong>
							</span>
						</div>
					</div>
					<div className="border-b w-10/12 border-gray-400 my-5 self-center"></div>
					<section className="py-24">
						<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
							<h2 className="font-bold text-3xl text-black mb-8">Produits disponibles</h2>
							{
								dataBoutik && dataBoutik.length > 0 ? (
									<div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
										{
											dataBoutik.map((p) => (
												<Link className="max-w-[384px] mx-auto">
													<div className="w-full max-w-sm aspect-square">
														<img onClick={() => ouvrirModalBoutik(`${p.boutique_nom}`,`${p.boutique_imageprincipale}`,`${p.boutique_image1}`,`${p.boutique_image2}`,`${p.boutique_image3}`,`${p.boutique_image4}`,`${p.boutique_prix}`,`${p.boutique_details}`,`${p.boutique_serial}`,`${p.boutique_type}`)} src={p.boutique_imageprincipale} className="w-full h-full rounded-xl border hover:shadow-md" id="transition-effect" alt={p.boutique_nom} />
													</div>
													<div className="mt-5 flex items-center justify-between">
														<div className="">
															<h6 onClick={() => ouvrirModalBoutik(`${p.boutique_nom}`,`${p.boutique_imageprincipale}`,`${p.boutique_image1}`,`${p.boutique_image2}`,`${p.boutique_image3}`,`${p.boutique_image4}`,`${p.boutique_prix}`,`${p.boutique_details}`,`${p.boutique_serial}`,`${p.boutique_type}`)} className="font-medium text-xl text-black mb-2">
																{p.boutique_nom}
															</h6>
															<h6 className="font-semibold text-xl leading-8 text-indigo-600">
																${p.boutique_prix}
															</h6>
														</div>
														<button onClick={() => ouvrirModalAchat(`${p.boutique_serial}`,`${p.boutique_nom}`,`${p.boutique_imageprincipale}`, `${p.boutique_prix}`)} className="p-2 min-[400px]:p-4 rounded-full bg-white border border-gray-300 flex items-center justify-center group shadow-sm shadow-transparent transition-all duration-500 hover:shadow-indigo-200 hover:border-gray-500 hover:bg-gray-100">
															<svg className="stroke-gray-900 transition-all duration-400 group-hover:stroke-black" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
																<path d="M12.6892 21.125C12.6892 22.0225 11.9409 22.75 11.0177 22.75C10.0946 22.75 9.34632 22.0225 9.34632 21.125M19.3749 21.125C19.3749 22.0225 18.6266 22.75 17.7035 22.75C16.7804 22.75 16.032 22.0225 16.032 21.125M4.88917 6.5L6.4566 14.88C6.77298 16.5715 6.93117 17.4173 7.53301 17.917C8.13484 18.4167 8.99525 18.4167 10.7161 18.4167H18.0056C19.7266 18.4167 20.587 18.4167 21.1889 17.9169C21.7907 17.4172 21.9489 16.5714 22.2652 14.8798L22.8728 11.6298C23.3172 9.25332 23.5394 8.06508 22.8896 7.28254C22.2398 6.5 21.031 6.5 18.6133 6.5H4.88917ZM4.88917 6.5L4.33203 3.25" stroke="" stroke-width="1.6" stroke-linecap="round" />
															</svg>
														</button>
													</div>
												</Link>
											))
										} 
									</div>
								) : (
									<div>
										Aucun
									</div>
								)
							}
						</div>		        			        
					</section>
				</div>
				<div className="w-full flex flex-col shadow-md" id="page-newsletter">
					<div className="w-full h-full bg-blue-600 bg-opacity-40 text-center flex flex-col justify-center">
						<p className="text-3xl font-serif mb-2">
							Ne manquez aucune informations ! 
						</p>
						<span className="text-sm">
							Abonnez-vous à la newsletter
						</span>
						<div className="mt-10 w-5/12 flex w-newsletter-item self-center">
							<input ref={clientemailNewsletterRef} value={clientemailNewsletter} onChange={(e) => setclientemailNewsletter(e.target.value)} className="w-newsletter-input mr-1 outline-none border-none" type="text" placeholder="E-mail" />
							<button onClick={envoyerMailnewsletter} className="bg-white px-3 font-bold text-blue-600">Envoyer</button>
						</div>
					</div>
				</div>
			</div>
			<PageFooter />
		</div>
  	);
}

export default PageIndex;
